import { Controller } from "@hotwired/stimulus";
import fly from "flyio";
import $ from "jquery";

export default class extends Controller {
  connect() {}

  toggle(event) {
    const starClassText = "p";
    const obj_type = event.currentTarget.dataset.type;
    const obj_id = event.currentTarget.dataset.id;

    $(event.currentTarget).find(starClassText);

    const currentTarget = event.currentTarget;

    fly
      .put("/api/v1/like", {
        like_type: obj_type,
        like_id: obj_id,
      })
      .then((res) => {
        const {
          data: { action, like, likeCount },
        } = res;
        if (action) {
          $(currentTarget).find(starClassText).text(`\n${likeCount}\n`);
          if (like) {
            $(currentTarget).find(starClassText).addClass("active");
          } else {
            $(currentTarget).find(starClassText).removeClass("active");
          }
        }
      });
  }
}
