import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["box"];

  connect() {
    for (let index = 0; index < this.boxTargets.length; index++) {
      const element = this.boxTargets[index];
      let input = $(element).find("input");
      if (input.length === 0) {
        input = $(element).find("textarea");
      }
      if (input.length === 0) return;

      input[0].addEventListener("input", (e) => {
        this.judgeEle(element, e);
      });
      this.judgeEle(element, { target: input[0] });
    }
  }

  judgeEle(element, e) {
    const regex = this.name2regex($(element).data("format"));
    if (e.target.value.match(regex)) {
      $(element).find(".error_msg").hide();
    } else {
      $(element).find(".error_msg").show();
    }
    this.formValidated(element);
  }

  formValidated(element) {
    const allError = $(".error_msg");
    const cssValue = allError.map((index, item) => {
      return $(item).css("display") || "null";
    });
    const allPass =
      cssValue.filter((_, item) => item === "none").length !== allError.length;
    $(element)
      .parents("form")
      .find("input[type=submit]")
      .prop("disabled", allPass);
  }

  name2regex(name) {
    switch (name) {
      case "present":
        return new RegExp("^(?!s*$).+");
      case "email":
        return new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      case "confirmation":
        const password = document.getElementById("account_password").value;
        if (password) {
          return new RegExp(`^(${password})$`);
        } else {
          false;
        }

      default:
        return name;
    }
  }
}
