import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  handleRoleChange() {
    if ($('input[name="account[role]"]:checked').val() === 'chef') {
      $('.chef-only').removeClass('d-none')
    } else {
      $('.chef-only').addClass('d-none')
    }
  }
}